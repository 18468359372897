import { Component } from "../lib/_component";
import ComponentType from "../lib/_dom-events.t";
import Events from "./carousel.events";
var maxScreen = 991;
var initialSlide = $(".carousel-inner").html();
export class Carousel extends Component implements ComponentType {
    name = 'Carousel';
    onDomReady() {
        this.init();
    }
    init() {
        this.elementRef = '#carousel-slider';
        this.element = $(this.elementRef);
        this.onWindowLoad();
        Events.slide.call(this);
        Events.resize.call(this);
        if (window.innerWidth < maxScreen) {
            $("#carousel-slider").attr("data-ride", "carousel");
        } else {
            $("#carousel-slider").removeAttr("data-ride");
        }
    }
    onWindowResize(e) {
        e.stopPropagation();
        this.windowResize(e);
    }
    onWindowLoad() {
        if (window.innerWidth > maxScreen) {
            $("#carousel-slider").removeAttr("data-ride");
        }
        else {
            $("#carousel-slider").attr("data-ride", "carousel");
            this.element.carousel('cycle');
        }
    }

    windowResize(e) {
        if(this.element.length > 0) {
            let $listSort = $("#carousel-slider");
            if (window.innerWidth > maxScreen) {
                this.element.carousel('pause');
                if ($listSort.attr('data-ride')) {
                    $listSort.removeAttr('data-ride');
                }
                $(".carousel-inner").html(initialSlide);
            } else {
                if (!$listSort.attr('data-ride')) {
                    $listSort.attr("data-ride", "carousel");
                }
                this.element.carousel('cycle');
            }
        }
    }

    sliding(e) {
        if(this.element.length > 0) {
            let itemsPerSlide, idx, totalItems;
            if (window.innerWidth <= maxScreen) {
                itemsPerSlide = 3;
                let $e = $(e.relatedTarget);
                idx = $e.index();
                totalItems = $('.carousel-item').length;
                if (idx >= totalItems - (itemsPerSlide - 1)) {
                    let it = itemsPerSlide - (totalItems - idx);
                    for (let i = 0; i < it; i++) {
                        if (e.direction == "left") {
                            $('.carousel-item').eq(i).appendTo('.carousel-inner');
                        }
                        else {
                            $('.carousel-item').eq(0).appendTo('.carousel-inner');
                        }
                    }
                }
            } else {
                e.preventDefault();
                this.element.carousel('pause');
                $(".carousel-inner").html(initialSlide);
            }
        }
    }
}