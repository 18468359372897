import { Component } from "../lib/_component";
import ComponentType from "../lib/_dom-events.t";
import Events from "./carousel-desktop.events";
var initialSlide = $(".carousel-inner").html();
export class CarouselDesktop extends Component implements ComponentType {
    name = 'CarouselDesktop';
    slideMovedTo;
    onDomReady() {
        this.init();
    }
    init() {
        this.elementRef = '#multiple-carousel-slider';
        this.element = $(this.elementRef);
        this.carouselInitialize();
        Events.onCardSlide.call(this);
        Events.onKeyPress.call(this);
    }
    onWindowResize(e) {
        e.stopPropagation();
        this.carouselInitialize();
    }
    carouselInitialize(){
        if(this.element.length > 0) {
            this.element.carousel({   
                pause: true,
                interval: false
            }).carousel(0);
            $("#multiple-carousel-slider .carousel-control-prev").addClass("action-disable");
            $("#multiple-carousel-slider .carousel-control-prev").attr("tabindex", "-1");
            $("#multiple-carousel-slider .carousel-control-prev").attr("href", '#');
            $("#multiple-carousel-slider .carousel-control-prev").attr("title", 'disabled');
            $("#multiple-carousel-slider .carousel-control-next").attr("title", 'enabled');
            
            var $listContainerLenght;
            $listContainerLenght =this.element.find('.carousel-item').length;               
            if((window.innerWidth > 991 || window.innerWidth < 991)  && $listContainerLenght >4){
                $('#multiple-carousel-slider .carousel-control-prev').css({'opacity': '1', 'pointer-events': 'all','visibility': 'visible'});
                $('#multiple-carousel-slider .carousel-control-next').css({'opacity': '1', 'pointer-events': 'all','visibility': 'visible'});
            }
        else if(window.innerWidth < 991 && $listContainerLenght > 3){
                $('#multiple-carousel-slider .carousel-control-prev').css({'opacity': '1', 'pointer-events': 'all','visibility': 'visible'});
                $('#multiple-carousel-slider .carousel-control-next').css({'opacity': '1', 'pointer-events': 'all','visibility': 'visible'});
        }
        else if(window.innerWidth < 768 && $listContainerLenght > 2){
                $('#multiple-carousel-slider .carousel-control-prev').css({'opacity': '1', 'pointer-events': 'all','visibility': 'visible'});
                $('#multiple-carousel-slider .carousel-control-next').css({'opacity': '1', 'pointer-events': 'all','visibility': 'visible'});
        }
        else if(window.innerWidth < 557 && $listContainerLenght > 1){
                $('#multiple-carousel-slider .carousel-control-prev').css({'opacity': '1', 'pointer-events': 'all','visibility': 'visible'});
                $('#multiple-carousel-slider .carousel-control-next').css({'opacity': '1', 'pointer-events': 'all','visibility': 'visible'});
            }
            else {
                $('#multiple-carousel-slider .carousel-control-prev').css({'opacity': '0', 'pointer-events': 'none','visibility': 'hidden'});
                $('#multiple-carousel-slider .carousel-control-next').css({'opacity': '0', 'pointer-events': 'none','visibility': 'hidden'});
                $('#multiple-carousel-slider .carousel-control-prev').addClass('d-none');
                $('#multiple-carousel-slider .carousel-control-next').addClass('d-none');
                $("#multiple-carousel-slider .carousel-control-prev").attr("tabindex", "0");
                $("#multiple-carousel-slider .carousel-control-prev").removeClass("action-disable");
            }
        }
    }
    onSliding(e) {
        var deskCnt=4, tabcnt=3, smcnt=2, mbcnt=1;
        if(e.direction === 'left'){
            if(window.innerWidth >= 991){
                $("#multiple-carousel-slider .carousel-inner .active.col-lg-3.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item").css("visibility", 'visible');
            }else if(window.innerWidth < 991 && window.innerWidth > 767){
                $("#multiple-carousel-slider .carousel-inner .active.ci.carousel-item+.carousel-item+.carousel-item+.carousel-item ").css("visibility", 'visible');
            }else if(window.innerWidth < 768 && window.innerWidth > 557){
                $("#multiple-carousel-slider .carousel-inner .active.ci.carousel-item+.carousel-item+.carousel-item").css("visibility", 'visible');
            }
        }
        if(e.to > 0){
            $("#multiple-carousel-slider .carousel-control-prev").attr("href", '#multiple-carousel-slider');
            $("#multiple-carousel-slider .carousel-control-prev").removeClass("action-disable");
            $("#multiple-carousel-slider .carousel-control-prev").attr("tabindex", "0");
            $("#multiple-carousel-slider .carousel-control-prev").attr("title", 'enabled');
        }else{
            $("#multiple-carousel-slider .carousel-control-prev").attr("href", '#');
            $("#multiple-carousel-slider .carousel-control-prev").attr("tabindex", "-1");
            $("#multiple-carousel-slider .carousel-control-prev").addClass("action-disable");
            $("#multiple-carousel-slider .carousel-control-prev").attr("title", 'disabled');
        }
        var $slidesLenght =this.element.find('.carousel-item').length;
        //START:next slide actions
        if(window.innerWidth >= 991){
            this.toggelNextSlideAction(deskCnt, e.to, $slidesLenght);
        }else if(window.innerWidth < 991 && window.innerWidth > 767){
            this.toggelNextSlideAction(tabcnt, e.to, $slidesLenght);
        }else if(window.innerWidth < 768 && window.innerWidth > 557){
            this.toggelNextSlideAction(smcnt, e.to, $slidesLenght);
        }else if(window.innerWidth < 557){
            this.toggelNextSlideAction(mbcnt, e.to, $slidesLenght);
        }
        // End:next slide action

        // START:keybord interaction flow
        if(e.direction === 'left' && this.slideMovedTo === 'next'){
            if(window.innerWidth >= 991){
                setTimeout(function(){ 
                    e.relatedTarget.nextElementSibling.nextElementSibling.nextElementSibling.firstElementChild.firstElementChild.firstElementChild.focus();
                    $("#multiple-carousel-slider .carousel-inner .active.col-lg-3.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item").css("visibility", 'hidden');
                 }, 500);
            }else if(window.innerWidth < 991 && window.innerWidth > 767){
                setTimeout(function(){ 
                    e.relatedTarget.nextElementSibling.nextElementSibling.firstElementChild.firstElementChild.firstElementChild.focus();
                    $("#multiple-carousel-slider .carousel-inner .active.col-lg-3.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item").css("visibility", 'hidden');
                 }, 500);
            }else if(window.innerWidth < 768 && window.innerWidth > 557){
                setTimeout(function(){ 
                    e.relatedTarget.nextElementSibling.firstElementChild.firstElementChild.firstElementChild.focus();
                    $("#multiple-carousel-slider .carousel-inner .active.col-lg-3.carousel-item + .carousel-item + .carousel-item + .carousel-item").css("visibility", 'hidden');
                 }, 500);
            } else if(window.innerWidth < 557){
                setTimeout(function(){ 
                    e.relatedTarget.firstElementChild.firstElementChild.firstElementChild.focus();
                    $("#multiple-carousel-slider .carousel-inner .active.col-lg-3.carousel-item + .carousel-item + .carousel-item").css("visibility", 'hidden');
                 }, 500);
            }
        }else if(e.direction === 'right' && this.slideMovedTo === 'prev'){
            setTimeout(function(){ 
                e.relatedTarget.firstElementChild.firstElementChild.firstElementChild.focus(); 
                e.relatedTarget.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.style.visibility = 'hidden';
            }, 500);
        }

        //END: keybord interaction flow
    }
    toggelNextSlideAction(countPerDevice, pos, totSlides){
          if((countPerDevice+pos) === totSlides){
                $("#multiple-carousel-slider .carousel-control-next").attr("href", '#');
                $("#multiple-carousel-slider .carousel-control-next").attr("tabindex", "-1");
                $("#multiple-carousel-slider .carousel-control-next").addClass("action-disable");
                $("#multiple-carousel-slider .carousel-control-next").attr("title", 'disabled');
            }else {
                $("#multiple-carousel-slider .carousel-control-next").attr("href", '#multiple-carousel-slider');
                $("#multiple-carousel-slider .carousel-control-next").attr("tabindex", "0");
                $("#multiple-carousel-slider .carousel-control-next").removeClass("action-disable");
                $("#multiple-carousel-slider .carousel-control-next").attr("title", 'enabled');
            }
    }
    triggerKeyPress(e){
            if(e.keyCode == 13 && e.target.dataset.slide === 'next'){
                this.slideMovedTo = e.target.dataset.slide;
            }else if(e.keyCode == 13 && e.target.dataset.slide === 'prev'){
                this.slideMovedTo = e.target.dataset.slide;
            }else if( e.target.dataset.slide === 'next' || e.target.dataset.slide === 'prev') {
                if(e.keyCode == 38 || e.keyCode == 40 ||e.keyCode == 39 ||e.keyCode == 37){
                    let $listSort = $("#multiple-carousel-slider");
                    $($listSort).attr('data-keyboard', 'false');
                }
               
            }
                    
    }

}